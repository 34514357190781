import React, { useState, useEffect, useRef, useCallback } from 'react';
import LogTable from './LogTable';
import '../styles.css';

const TimecodeSlate = () => {
  const [activeTab, setActiveTab] = useState('Slate');
  const [frameRate, setFrameRate] = useState(24);
  const [scene, setScene] = useState('1');
  const [slate, setSlate] = useState('1');
  const [take, setTake] = useState(1);
  const [timecode, setTimecode] = useState('00:00:00:00');
  const [customTimecode, setCustomTimecode] = useState('00:00:00');
  const [isMuted, setIsMuted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const [location, setLocation] = useState('INT');
  const [timeOfDay, setTimeOfDay] = useState('DAY');
  const [sound, setSound] = useState('SYNC');
  const [comment, setComment] = useState('');

  const startTime = useRef(Date.now());
  const audioContext = useRef(null);
  const oscillator = useRef(null);
  const freezeTimeout = useRef(null);
  const isFrozen = useRef(false);
  const currentTimecode = useRef('00:00:00:00');

  useEffect(() => {
    // Load saved data from localStorage
    const savedFrameRate = localStorage.getItem('frameRate');
    const savedScene = localStorage.getItem('scene');
    const savedSlate = localStorage.getItem('slate');
    const savedTake = localStorage.getItem('take');
    const savedLogs = localStorage.getItem('logs');
    const savedStartTime = localStorage.getItem('startTime');
    const savedLocation = localStorage.getItem('location');
    const savedTimeOfDay = localStorage.getItem('timeOfDay');
    const savedSound = localStorage.getItem('sound');

    if (savedFrameRate) setFrameRate(Number(savedFrameRate));
    if (savedScene) setScene(savedScene);
    if (savedSlate) setSlate(savedSlate);
    if (savedTake) setTake(Number(savedTake));
    if (savedLogs) setLogs(JSON.parse(savedLogs));
    if (savedStartTime) startTime.current = Number(savedStartTime);
    if (savedLocation) setLocation(savedLocation);
    if (savedTimeOfDay) setTimeOfDay(savedTimeOfDay);
    if (savedSound) setSound(savedSound);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const updateTimecode = useCallback(() => {
    const now = Date.now();
    const msSinceStart = now - startTime.current;
    const hours = String(Math.floor(msSinceStart / 3600000)).padStart(2, '0');
    const minutes = String(Math.floor((msSinceStart % 3600000) / 60000)).padStart(2, '0');
    const seconds = String(Math.floor((msSinceStart % 60000) / 1000)).padStart(2, '0');
    const frames = String(Math.floor((msSinceStart % 1000) / (1000 / frameRate))).padStart(2, '0');
    currentTimecode.current = `${hours}:${minutes}:${seconds}:${frames}`;
    setTimecode(currentTimecode.current);
    localStorage.setItem('startTime', startTime.current);
    localStorage.setItem('timecode', currentTimecode.current);
  }, [frameRate]);

  useEffect(() => {
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
    const interval = setInterval(() => {
      if (!isFrozen.current) {
        updateTimecode();
      }
    }, 1000 / frameRate);
    return () => {
      clearInterval(interval);
      if (oscillator.current) {
        oscillator.current.stop();
        oscillator.current.disconnect();
      }
    };
  }, [frameRate, updateTimecode]);

  const handleFrameRateChange = (e) => {
    const newFrameRate = parseInt(e.target.value, 10);
    setFrameRate(newFrameRate);
    localStorage.setItem('frameRate', newFrameRate);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const handleSyncTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const frames = String(Math.floor(now.getMilliseconds() / (1000 / frameRate))).padStart(2, '0');
    const syncedTimecode = `${hours}:${minutes}:${seconds}:${frames}`;
    setTimecode(syncedTimecode);
    currentTimecode.current = syncedTimecode;
    startTime.current = now.getTime() - ((now.getHours() * 3600000) + (now.getMinutes() * 60000) + (now.getSeconds() * 1000) + now.getMilliseconds());
    localStorage.setItem('startTime', startTime.current);
    localStorage.setItem('timecode', syncedTimecode);
  };

  const handleClearAllData = () => {
    if (window.confirm('Are you sure you want to clear all data? This action cannot be undone.')) {
      setLogs([]);
      setScene('1');
      setSlate('1');
      setTake(1);
      setTimecode('00:00:00:00');
      setLocation('INT');
      setTimeOfDay('DAY');
      setSound('SYNC');
      localStorage.removeItem('frameRate');
      localStorage.removeItem('scene');
      localStorage.removeItem('slate');
      localStorage.removeItem('take');
      localStorage.removeItem('logs');
      localStorage.removeItem('startTime');
      localStorage.removeItem('timecode');
      localStorage.removeItem('location');
      localStorage.removeItem('timeOfDay');
      localStorage.removeItem('sound');
    }
  };

  const handleSetCustomTimecode = () => {
    const [hours, minutes, seconds] = customTimecode.split(':').map(Number);
    const customTimeInMs = (hours * 3600000) + (minutes * 60000) + (seconds * 1000);
    startTime.current = Date.now() - customTimeInMs;
    const customTimecodeWithFrames = `${customTimecode}:00`;
    setTimecode(customTimecodeWithFrames);
    currentTimecode.current = customTimecodeWithFrames;
    localStorage.setItem('startTime', startTime.current);
    localStorage.setItem('timecode', customTimecodeWithFrames);
  };

  const handleResetTimecode = () => {
    const resetTimecode = '00:00:00:00';
    setTimecode(resetTimecode);
    currentTimecode.current = resetTimecode;
    startTime.current = Date.now();
    localStorage.setItem('startTime', startTime.current);
    localStorage.setItem('timecode', resetTimecode);
  };

  const incrementAlphaNumeric = (value) => {
    const regex = /(\d+|[a-zA-Z]+)$/;
    const match = value.match(regex);
    if (!match) return value;

    const part = match[0];
    const index = match.index;

    if (isNaN(part)) {
      // Alphabetic increment
      let carry = 1;
      let result = '';

      for (let i = part.length - 1; i >= 0; i--) {
        let charCode = part.charCodeAt(i) + carry;
        if (charCode > 90 && charCode < 97) {
          charCode = 65;
          carry = 1;
        } else if (charCode > 122) {
          charCode = 97;
          carry = 1;
        } else {
          carry = 0;
        }
        result = String.fromCharCode(charCode) + result;
      }

      if (carry === 1) {
        result = 'A' + result;
      }

      return value.substring(0, index) + result;
    } else {
      // Numeric increment
      const num = (parseInt(part, 10) + 1).toString();
      return value.substring(0, index) + num;
    }
  };

  const decrementAlphaNumeric = (value) => {
    const regex = /(\d+|[a-zA-Z]+)$/;
    const match = value.match(regex);
    if (!match) return value;
  
    const part = match[0];
    const index = match.index;
  
    if (isNaN(part)) {
      // Alphabetic decrement
      let carry = 1;
      let result = '';
  
      for (let i = part.length - 1; i >= 0; i--) {
        let charCode = part.charCodeAt(i) - carry;
        if (charCode < 65) {
          charCode = 90;
          carry = 1;
        } else if (charCode < 97 && part.charCodeAt(i) >= 97) {
          charCode = 122;
          carry = 1;
        } else {
          carry = 0;
        }
        result = String.fromCharCode(charCode) + result;
      }
  
      if (carry === 1 && result.length > 0) {
        result = result.substring(1);
      }
  
      return value.substring(0, index) + (result || 'A');
    } else {
      // Numeric decrement
      let num = parseInt(part, 10) - 1;
      if (num < 0) num = 0;
      return value.substring(0, index) + num.toString();
    }
  };
  
  const handleIncrementScene = () => {
    const newScene = incrementAlphaNumeric(scene);
    setScene(newScene);
    localStorage.setItem('scene', newScene);
  };
  
  const handleDecrementScene = () => {
    const newScene = decrementAlphaNumeric(scene);
    setScene(newScene);
    localStorage.setItem('scene', newScene);
  };
  
  const handleIncrementSlate = () => {
    const newSlate = incrementAlphaNumeric(slate);
    setSlate(newSlate);
    localStorage.setItem('slate', newSlate);
  };
  
  const handleDecrementSlate = () => {
    const newSlate = decrementAlphaNumeric(slate);
    setSlate(newSlate);
    localStorage.setItem('slate', newSlate);
  };
  
  const handleIncrementTake = () => {
    const newTake = incrementAlphaNumeric(take.toString());
    setTake(parseInt(newTake, 10));
    localStorage.setItem('take', newTake);
  };
  
  const handleDecrementTake = () => {
    if (take > 1) {
      const newTake = decrementAlphaNumeric(take.toString());
      setTake(parseInt(newTake, 10));
      localStorage.setItem('take', newTake);
    }
  };
  
  const handleTimecodeClick = () => {
    if (!isMuted && audioContext.current) {
      oscillator.current = audioContext.current.createOscillator();
      oscillator.current.type = 'sine';
      oscillator.current.frequency.setValueAtTime(1000, audioContext.current.currentTime);
      oscillator.current.connect(audioContext.current.destination);
      oscillator.current.start();
      oscillator.current.stop(audioContext.current.currentTime + 1);
    }
  
    isFrozen.current = true;
    setIsPaused(true);
    clearTimeout(freezeTimeout.current);
    freezeTimeout.current = setTimeout(() => {
      isFrozen.current = false;
      setIsPaused(false);
      setTimecode(currentTimecode.current);
    }, 1000);
  
    // Add to log
    const newLogs = [...logs, { scene, slate, take, timecode: currentTimecode.current, frameRate, location, timeOfDay, sound, comment }];
    setLogs(newLogs);
    localStorage.setItem('logs', JSON.stringify(newLogs));
  
    // Increment the take
    handleIncrementTake();
  };
  
  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };
  
  const exportToCSV = (data) => {
    const csvRows = [
      ['Scene', 'Slate', 'Take', 'Timecode', 'Frame Rate', 'Location', 'Time of Day', 'Sound', 'Comment'],
      ...data.map(row => [row.scene, row.slate, row.take, row.timecode, row.frameRate, row.location, row.timeOfDay, row.sound, row.comment])
    ];
  
    const csvContent = csvRows.map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'timecode_logs.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <h3>Welcome to Timecode Slate App by Matt Price from <a href="https://soundrolling.com" target="_blank" rel="noopener noreferrer">Soundrolling.com</a></h3><span>V11.2</span>
      </header>
      <main className="container">
        <div className="tab-row">
          <button
            className={activeTab === 'Slate' ? 'active' : ''}
            onClick={() => setActiveTab('Slate')}
          >
            Slate
          </button>
          <button
            className={activeTab === 'Report' ? 'active' : ''}
            onClick={() => setActiveTab('Report')}
          >
            Report
          </button>
          <button
            className={activeTab === 'Settings' ? 'active' : ''}
            onClick={() => setActiveTab('Settings')}
          >
            Settings
          </button>
        </div>
        {activeTab === 'Slate' && (
          <div>
            <div
              id="timecodeContainer"
              onClick={handleTimecodeClick}
              className={isPaused ? 'paused' : ''}
            >
              <div id="timecode" className={isPaused ? 'paused' : ''}>{timecode}</div>
            </div>
            <table id="infoDisplay" className="info-table">
              <thead>
                <tr>
                  <th>Scene</th>
                  <th>Slate</th>
                  <th>Take</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{scene}</td>
                  <td>{slate}</td>
                  <td>{take}</td>
                </tr>
                <tr>
                  <td>{location}</td>
                  <td>{timeOfDay}</td>
                  <td>{sound}</td>
                </tr>
              </tbody>
            </table>
            <button className="primary-button" onClick={handleFullscreenToggle}>Fullscreen</button>
            <div className="label-row">
              <span>(tap the timecode readout to play tone and freeze the display for 1 second)</span>
            </div>
            <hr className="divider" />
            <div className="input-group">
              <div className="input-row">
                <label>Scene</label>
                <button className="primary-button" onClick={handleDecrementScene}>-</button>
                <input
                  type="text"
                  value={scene}
                  pattern="[a-zA-Z0-9]*"
                  onChange={(e) => {
                    setScene(e.target.value);
                    localStorage.setItem('scene', e.target.value);
                  }}
                />
                <button className="primary-button" onClick={handleIncrementScene}>+</button>
              </div>
              <div className="input-row">
                <label>Slate</label>
                <button className="primary-button" onClick={handleDecrementSlate}>-</button>
                <input
                  type="text"
                  value={slate}
                  pattern="[a-zA-Z0-9]*"
                  onChange={(e) => {
                    setSlate(e.target.value);
                    localStorage.setItem('slate', e.target.value);
                  }}
                />
                <button className="primary-button" onClick={handleIncrementSlate}>+</button>
              </div>
              <div className="input-row">
                <label>Take</label>
                <button className="primary-button" onClick={handleDecrementTake}>-</button>
                <input
                  type="text"
                  value={take}
                  pattern="[0-9]*"
                  onChange={(e) => {
                    const newTake = parseInt(e.target.value, 10) || 0;
                    setTake(newTake);
                    localStorage.setItem('take', newTake);
                  }}
                />
                <button className="primary-button" onClick={handleIncrementTake}>+</button>
              </div>
            </div>
            <div className="input-group">
              <span>Press any button below to swap between values.</span>
              <div className="toggle-group">
                <button className={`toggle-button ${location === 'INT' ? 'active' : ''}`} onClick={() => {
                  const newLocation = location === 'INT' ? 'EXT' : 'INT';
                  setLocation(newLocation);
                  localStorage.setItem('location', newLocation);
                }}>INT / EXT</button><p></p>
                <button className={`toggle-button ${timeOfDay === 'DAY' ? 'active' : ''}`} onClick={() => {
                  const newTimeOfDay = timeOfDay === 'DAY' ? 'NIGHT' : 'DAY';
                  setTimeOfDay(newTimeOfDay);
                  localStorage.setItem('timeOfDay', newTimeOfDay);
                }}>DAY / NIGHT</button><p></p>
                <button className={`toggle-button ${sound === 'SYNC'   ? 'active' : ''}`} onClick={() => {
    const newSound = sound === 'SYNC' ? 'MOS' : 'SYNC';
    setSound(newSound);
    localStorage.setItem('sound', newSound);
  }}>SYNC / MOS</button>
</div>
</div>
</div>
)}
{activeTab === 'Report' && (
<div>
  <LogTable logs={logs} setLogs={setLogs} />
  <button className="export-button" onClick={() => exportToCSV(logs)}>Export to CSV</button>
</div>
)}
{activeTab === 'Settings' && (
<div>
  <div id="timecodeContainer">
    <div id="timecode" className={isPaused ? 'paused' : ''}>{timecode}</div>
  </div>
  <div className="frame-rate-container">
    <label htmlFor="frameRate">Frame Rate:</label>
    <select id="frameRate" className="half-width" value={frameRate} onChange={handleFrameRateChange}>
    <option value="23.976">23.976 FPS</option>
    <option value="23.98">23.98 FPS</option>
      <option value="24">24 FPS</option>
      <option value="25">25 FPS</option>
      <option value="30">30 FPS</option>
    </select>
  </div>
  <div className="button-row">
    <button className="primary-button" onClick={handleSyncTime}>
      Sync to Current Time
    </button>
    <button className="primary-button" onClick={handleMuteToggle}>
      {isMuted ? 'Unmute' : 'Mute'}
    </button>
    <button className="danger-button" onClick={handleClearAllData}>
      Clear All Data
    </button>
    <button className="primary-button" onClick={handleInstallClick}>
      Install as Offline App
    </button>
  </div>
  <div className="custom-timecode-container">
    <h3>Custom Timecode</h3>
    <label htmlFor="customTimecode">Custom Timecode (HH:MM:SS)</label>
    <input
      id="customTimecode"
      type="text"
      value={customTimecode}
      onChange={(e) => setCustomTimecode(e.target.value)}
    />
    <button className="primary-button" onClick={handleSetCustomTimecode}>Start Custom Timecode</button>
    <button className="primary-button" onClick={handleResetTimecode}>Reset to 00:00:00:00</button>
  </div>
</div>
)}
</main>
{isFullscreen && (
<div className="fullscreen-overlay">
  <div className="exit-fullscreen" onClick={handleFullscreenToggle}>X</div>
  <div className="fullscreen-content" onClick={handleTimecodeClick}>
    <div id="timecode" className={`fullscreen-timecode ${isPaused ? 'paused' : ''}`}>{timecode}</div>
    <div className="fullscreen-info">
      <div>Scene {scene}</div>
      <div>Slate {slate}</div>
      <div>Take {take}</div>
      <div>{location}</div>
      <div>{timeOfDay}</div>
      <div>{sound}</div>
    </div>
  </div>
</div>
)}
</div>
);
};

export default TimecodeSlate;