import React, { useState } from 'react';

const LogTable = ({ logs, setLogs }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedLog, setEditedLog] = useState({});

  const handleEditClick = (index) => {
    setEditingIndex(index);
    setEditedLog(logs[index]);
  };

  const handleSaveClick = (index) => {
    const updatedLogs = [...logs];
    updatedLogs[index] = editedLog;
    setLogs(updatedLogs);
    localStorage.setItem('logs', JSON.stringify(updatedLogs));
    setEditingIndex(null);
    setEditedLog({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedLog((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteClick = (index) => {
    if (window.confirm('Are you sure you want to delete this log entry?')) {
      const updatedLogs = logs.filter((_, i) => i !== index);
      setLogs(updatedLogs);
      localStorage.setItem('logs', JSON.stringify(updatedLogs));
    }
  };

  return (
    <table className="log-table">
      <thead>
        <tr>
          <th>Scene</th>
          <th>Slate</th>
          <th>Take</th>
          <th>Timecode</th>
          <th>Frame Rate</th>
          <th>Location</th>
          <th>Time of Day</th>
          <th>Sound</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, index) => (
          <React.Fragment key={index}>
            <tr>
              {editingIndex === index ? (
                <>
                  <td><input name="scene" value={editedLog.scene} onChange={handleInputChange} /></td>
                  <td><input name="slate" value={editedLog.slate} onChange={handleInputChange} /></td>
                  <td><input name="take" value={editedLog.take} onChange={handleInputChange} /></td>
                  <td>{log.timecode}</td>
                  <td><input name="frameRate" value={editedLog.frameRate} onChange={handleInputChange} /></td>
                  <td><input name="location" value={editedLog.location} onChange={handleInputChange} /></td>
                  <td><input name="timeOfDay" value={editedLog.timeOfDay} onChange={handleInputChange} /></td>
                  <td><input name="sound" value={editedLog.sound} onChange={handleInputChange} /></td>
                  <td>
                    <button onClick={() => handleSaveClick(index)}>Save</button>
                    <button onClick={() => setEditingIndex(null)}>Cancel</button>
                  </td>
                </>
              ) : (
                <>
                  <td>{log.scene}</td>
                  <td>{log.slate}</td>
                  <td>{log.take}</td>
                  <td>{log.timecode}</td>
                  <td>{log.frameRate}</td>
                  <td>{log.location}</td>
                  <td>{log.timeOfDay}</td>
                  <td>{log.sound}</td>
                  <td>
                    <button onClick={() => handleEditClick(index)}>Edit</button>
                    <button onClick={() => handleDeleteClick(index)}>Delete</button>
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td colSpan="9">
                <strong>Comment:</strong>
                {editingIndex === index ? (
                  <input
                    name="comment"
                    value={editedLog.comment}
                    onChange={handleInputChange}
                    style={{ width: '100%' }}
                  />
                ) : (
                  log.comment
                )}
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default LogTable;